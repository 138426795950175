import {
  GET_FILING,
  GET_FILING_SUCCESS,
  GET_FILING_ERROR,
  INVALIDATE_FILING,
  UPSERT_OBJECTION_RESPONDER_SUCCESS,
  UPSERT_FILING_CUSTOM_FIELD,
  DELETE_FILING_CUSTOM_FIELD,
  ADD_FILING_ATTACHMENTS,
  DELETE_FILING_ATTACHMENTS,
  GET_FILING_FORM_STATUS,
  GET_FILING_FORM_STATUS_SUCCESS,
  GET_FILING_FORM_STATUS_ERROR,
  GET_FILING_RATE_STATUS,
  GET_FILING_RATE_STATUS_SUCCESS,
  GET_FILING_RATE_STATUS_ERROR,
} from '../actions/filingActions';

import {
  ADD_FORM_SCHEDULE_ITEM,
  EDIT_FORM_SCHEDULE_ITEM,
  DELETE_FORM_SCHEDULE_ITEM,
  ADD_FORM_SCHEDULE_ITEM_ATTACHMENT,
  DELETE_FORM_SCHEDULE_ITEM_ATTACHMENT,
  ADD_RATE_SCHEDULE_ITEM,
  EDIT_RATE_SCHEDULE_ITEM,
  DELETE_RATE_SCHEDULE_ITEM,
  ADD_RATE_SCHEDULE_ITEM_ATTACHMENT,
  EDIT_STATE_GENERAL_INFORMATION,
  EDIT_STATE_FEES,
  EDIT_STATE_RATE_DATA,
  DELETE_FILING_ATTACHMENT,
  EDIT_FILING,
  EDIT_FILING_EFFECTIVE_DATE_OVERRIDE,
  EDIT_FILING_EFFECTIVE_DATE_RENEWAL_OVERRIDE,
  EDIT_FILING_RESPOND_BY_DATE_OVERRIDE,
  EDIT_FILING_PROJECT,
  EDIT_FILING_PRODUCT,
  DELETE_RATE_SCHEDULE_ITEM_ATTACHMENT,
  ADD_SUPPORTING_DOCUMENTATION_ITEM,
  EDIT_SUPPORTING_DOCUMENTATION_ITEM,
  DELETE_SUPPORTING_DOCUMENTATION_ITEM,
  ADD_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT,
  DELETE_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT,
} from '../actions/stateDetailsActions';
import {
  ADD_LIBRARY_FORM_CUSTOM_FIELD,
  EDIT_LIBRARY_FORM_CUSTOM_FIELD,
  DELETE_LIBRARY_FORM_CUSTOM_FIELD,
} from '../actions/formsLibraryActions';
import {
  ADD_LIBRARY_RATE_CUSTOM_FIELD,
  EDIT_LIBRARY_RATE_CUSTOM_FIELD,
  DELETE_LIBRARY_RATE_CUSTOM_FIELD,
} from '../actions/ratesLibraryActions';

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FILING: {
      return {
        isFetching: true,
        data: null,
        error: null,
      };
    }
    case INVALIDATE_FILING: {
      return {
        isFetching: false,
        data: null,
        error: null,
      };
    }
    case GET_FILING_SUCCESS: {
      return {
        isFetching: false,
        data: action.payload,
        error: null,
      };
    }
    case GET_FILING_ERROR: {
      return {
        isFetching: false,
        data: null,
        error: action.payload,
      };
    }
    case EDIT_FILING: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: action.payload,
      };
    }
    case EDIT_FILING_PROJECT: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          project: action.payload,
        },
      };
    }
    case EDIT_FILING_PRODUCT: {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          product: action.payload,
        },
      };
    }
    case EDIT_FILING_EFFECTIVE_DATE_OVERRIDE: {
      const { effectiveDate } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          overrides: {
            ...state.data.overrides,
            effectiveDate,
          },
        },
      };
    }
    case EDIT_FILING_EFFECTIVE_DATE_RENEWAL_OVERRIDE: {
      const { effectiveDateRenewal } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          overrides: {
            ...state.data.overrides,
            effectiveDateRenewal,
          },
        },
      };
    }
    case EDIT_FILING_RESPOND_BY_DATE_OVERRIDE: {
      const { overrides } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          overrides,
        },
      };
    }
    case ADD_FORM_SCHEDULE_ITEM: {
      const { form } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          forms: [...state.data.forms, form],
        },
      };
    }
    case ADD_FORM_SCHEDULE_ITEM_ATTACHMENT: {
      const { formId, attachment } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          forms: state.data.forms.map(form => {
            if (form._id === formId) {
              return {
                ...form,
                attachments: [...form.attachments, attachment],
              };
            }
            return form;
          }),
        },
      };
    }
    case DELETE_FORM_SCHEDULE_ITEM_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { formId, attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          forms: state.data.forms.map(form => {
            if (form._id === formId) {
              return {
                ...form,
                attachments: form.attachments.filter(attachment => attachment._id !== attachmentId),
              };
            }
            return form;
          }),
        },
      };
    }
    case EDIT_FORM_SCHEDULE_ITEM: {
      const { form } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          forms: state.data.forms.map(f => {
            if (form && form._id === f._id) {
              return form;
            }
            return f;
          }),
        },
      };
    }
    case DELETE_FORM_SCHEDULE_ITEM: {
      const { formId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          forms: state.data.forms.filter(form => form._id !== formId),
        },
      };
    }
    case ADD_SUPPORTING_DOCUMENTATION_ITEM: {
      const { supporting } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          supportings: [...state.data.supportings, supporting],
        },
      };
    }
    case EDIT_SUPPORTING_DOCUMENTATION_ITEM: {
      const { supporting } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          supportings: state.data.supportings.map(f => {
            if (supporting && supporting._id === f._id) {
              return supporting;
            }
            return f;
          }),
        },
      };
    }
    case DELETE_SUPPORTING_DOCUMENTATION_ITEM: {
      const { supportingId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          supportings: state.data.supportings
            .filter(supporting => supporting._id !== supportingId),
        },
      };
    }
    case ADD_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT: {
      const { supportingId, attachment } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          supportings: state.data.supportings.map(supporting => {
            if (supporting._id === supportingId) {
              return {
                ...supporting,
                attachments: [...supporting.attachments, attachment],
              };
            }
            return supporting;
          }),
        },
      };
    }
    case DELETE_SUPPORTING_DOCUMENTATION_ITEM_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { supportingId, attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          supportings: state.data.supportings.map(supporting => {
            if (supporting._id === supportingId) {
              return {
                ...supporting,
                attachments: supporting.attachments
                  .filter(attachment => attachment._id !== attachmentId),
              };
            }
            return supporting;
          }),
        },
      };
    }
    case ADD_RATE_SCHEDULE_ITEM: {
      const { rate } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          rates: [...state.data.rates, rate],
        },
      };
    }
    case EDIT_RATE_SCHEDULE_ITEM: {
      const { rate } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          rates: state.data.rates.map(f => {
            if (rate && rate._id === f._id) {
              return rate;
            }
            return f;
          }),
        },
      };
    }
    case DELETE_RATE_SCHEDULE_ITEM: {
      const { rateId } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          rates: state.data.rates.filter(rate => rate._id !== rateId),
        },
      };
    }
    case ADD_RATE_SCHEDULE_ITEM_ATTACHMENT: {
      const { rateId, attachment } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          rates: state.data.rates.map(rate => {
            if (rate._id === rateId) {
              return {
                ...rate,
                attachments: [...rate.attachments, attachment],
              };
            }
            return rate;
          }),
        },
      };
    }
    case DELETE_RATE_SCHEDULE_ITEM_ATTACHMENT: {
      if (!state.data) {
        return state;
      }

      const { rateId, attachmentId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          rates: state.data.rates.map(rate => {
            if (rate._id === rateId) {
              return {
                ...rate,
                attachments: rate.attachments.filter(attachment => attachment._id !== attachmentId),
              };
            }
            return rate;
          }),
        },
      };
    }
    case EDIT_STATE_GENERAL_INFORMATION: {
      const { data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          generalInformation: data,
        },
      };
    }
    case EDIT_STATE_FEES: {
      const { data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          fee: data,
        },
      };
    }
    case EDIT_STATE_RATE_DATA: {
      const { data } = action.payload;

      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          rateData: data,
        },
      };
    }
    case DELETE_FILING_ATTACHMENT: {
      return {
        ...state,
        data: {
          ...state.data,
          overrides: {
            ...state.data.overrides,
            attachment: null,
          },
        },
      };
    }
    case UPSERT_FILING_CUSTOM_FIELD: {
      const customField = action.payload;
      const customFields = (state.data
        && state.data.additions
        && state.data.additions.customFields) || [];

      const list = customFields.find(field => field._id === customField._id)
        ? customFields.map(field => (field._id === customField._id ? customField : field))
        : [...customFields, action.payload];

      return {
        ...state,
        data: {
          ...state.data,
          additions: {
            ...state.data.additions,
            customFields: list,
          },
        },
      };
    }
    case DELETE_FILING_CUSTOM_FIELD: {
      const customField = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          additions: {
            ...state.data.additions,
            customFields: state.data.additions.customFields
              .filter(field => customField.customFieldValueId !== field._id),
          },
        },
      };
    }
    case UPSERT_OBJECTION_RESPONDER_SUCCESS: {
      const { objectionId, responders } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          meta: {
            ...state.data.meta,
            objections: {
              ...state.data.meta && state.data.meta.objections,
              [objectionId]: { responders },
            },
          },
        },
      };
    }
    case ADD_FILING_ATTACHMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          attachments: [...state.data.attachments, action.payload],
        },
      };
    }
    case DELETE_FILING_ATTACHMENTS: {
      return {
        ...state,
        data: {
          ...state.data,
          attachments: state.data.attachments
            .filter(attachment => attachment._id !== action.payload),
        },
      };
    }
    case GET_FILING_FORM_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          formStatus: null,
        }
      }
    }
    case GET_FILING_FORM_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          formStatus: action.payload?.length ? action.payload[0] : null,
        }
      }
    }
    case GET_FILING_FORM_STATUS_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          formStatus: null,
        }
      }
    }
    case GET_FILING_RATE_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: null,
        }
      }
    }
    case GET_FILING_RATE_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: action.payload?.length ? action.payload[0] : null,
        }
      }
    }
    case GET_FILING_RATE_STATUS_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: null,
        }
      }
    }
    case ADD_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.data?.formStatus?.customFields) {
        return state;
      }

      const {
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          formStatus: {
            ...state.data.formStatus,
            customFields: [...state.data.formStatus.customFields, data],
          }
        }
      };
    }
    case EDIT_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.data?.formStatus?.customFields) {
        return state;
      }

      const {
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          formStatus: {
            ...state.data.formStatus,
            customFields: state.data.formStatus.customFields.map(customField => {
              if (customField._id === customFieldRecordId) {
                return data;
              }
              return customField;
            })
          }
        }
      };
    }
    case DELETE_LIBRARY_FORM_CUSTOM_FIELD: {
      if (!state.data?.formStatus?.customFields) {
        return state;
      }

      const {
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          formStatus: {
            ...state.data.formStatus,
            customFields: state.data.formStatus
              .customFields
              .filter(customField => customField._id !== customFieldRecordId),
          }
        }
      };
    }
    case ADD_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data?.rateStatus?.customFields) {
        return state;
      }

      const {
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: {
            ...state.data.rateStatus,
            customFields: [...state.data.rateStatus.customFields, data],
          }
        }
      };
    }
    case EDIT_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data?.rateStatus?.customFields) {
        return state;
      }

      const {
        customFieldRecordId,
        data,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: {
            ...state.data.rateStatus,
            customFields: state.data.rateStatus.customFields.map(customField => {
              if (customField._id === customFieldRecordId) {
                return data;
              }
              return customField;
            })
          }
        }
      };
    }
    case DELETE_LIBRARY_RATE_CUSTOM_FIELD: {
      if (!state.data?.rateStatus?.customFields) {
        return state;
      }

      const {
        customFieldRecordId,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          rateStatus: {
            ...state.data.rateStatus,
            customFields: state.data.rateStatus
              .customFields
              .filter(customField => customField._id !== customFieldRecordId),
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
