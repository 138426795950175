import axios from 'axios';
import { notification } from 'antd';
import { setAuthToken, URL } from './index';

export const GET_DRIVES = 'GET_DRIVES';
export const GET_DRIVES_SUCCESS = 'GET_DRIVES_SUCCESS';
export const GET_DRIVES_ERROR = 'GET_DRIVES_ERROR';
export const GET_DRIVES_BREADCRUMB = 'GET_DRIVES_BREADCRUMB';
export const GET_DRIVES_BREADCRUMB_SUCCESS = 'GET_DRIVES_BREADCRUMB_SUCCESS';
export const GET_DRIVES_BREADCRUMB_ERROR = 'GET_DRIVES_BREADCRUMB_ERROR';
export const GET_DRIVES_TREE = 'GET_DRIVES_TREE';
export const GET_DRIVES_TREE_SUCCESS = 'GET_DRIVES_TREE_SUCCESS';
export const GET_DRIVES_TREE_ERROR = 'GET_DRIVES_TREE_ERROR';
export const GET_DRIVE_SEARCH_RESULTS = 'GET_DRIVE_SEARCH_RESULTS';
export const GET_DRIVE_SEARCH_RESULTS_SUCCESS = 'GET_DRIVE_SEARCH_RESULTS_SUCCESS';
export const GET_DRIVE_SEARCH_RESULTS_ERROR = 'GET_DRIVE_SEARCH_RESULTS_ERROR';
export const GET_DRIVE_SEARCH_CONTENT_RESULTS = 'GET_DRIVE_SEARCH_CONTENT_RESULTS';
export const GET_DRIVE_SEARCH_CONTENT_RESULTS_SUCCESS = 'GET_DRIVE_SEARCH_CONTENT_RESULTS_SUCCESS';
export const GET_DRIVE_SEARCH_CONTENT_RESULTS_ERROR = 'GET_DRIVE_SEARCH_CONTENT_RESULTS_ERROR';
export const GET_DRIVE_TRASH = 'GET_DRIVE_TRASH';
export const GET_DRIVE_TRASH_SUCCESS = 'GET_DRIVE_TRASH_SUCCESS';
export const GET_DRIVE_TRASH_ERROR = 'GET_DRIVE_TRASH_ERROR';
export const EMPTY_DRIVE_TRASH = 'EMPTY_DRIVE_TRASH';
export const EMPTY_DRIVE_TRASH_SUCCESS = 'EMPTY_DRIVE_TRASH_SUCCESS';
export const EMPTY_DRIVE_TRASH_ERROR = 'EMPTY_DRIVE_TRASH_ERROR';
export const WOPI_GET_DRIVE_FILE_INFO = 'WOPI_GET_DRIVE_FILE_INFO';
export const WOPI_GET_DRIVE_FILE_INFO_SUCCESS = 'WOPI_GET_DRIVE_FILE_INFO_SUCCESS';
export const WOPI_GET_DRIVE_FILE_INFO_ERROR = 'WOPI_GET_DRIVE_FILE_INFO_ERROR';
export const WOPI_GET_DRIVE_FILE_CONTENT = 'WOPI_GET_DRIVE_FILE_CONTENT';
export const WOPI_GET_DRIVE_FILE_CONTENT_SUCCESS = 'WOPI_GET_DRIVE_FILE_CONTENT_SUCCESS';
export const WOPI_GET_DRIVE_FILE_CONTENT_ERROR = 'WOPI_GET_DRIVE_FILE_CONTENT_ERROR';
export const GET_DRIVE_FILE_DOWNLOAD_URL = 'GET_DRIVE_FILE_DOWNLOAD_URL';
export const DOWNLOAD_DRIVE_FOLDER_ZIP = 'DOWNLOAD_DRIVE_FOLDER_ZIP';
export const ADD_DRIVE = 'ADD_DRIVE';
export const ADD_DRIVE_FILE = 'ADD_DRIVE_FILE';
export const WOPI_HANDLE_HEADERS = 'WOPI_HANDLE_HEADERS';
export const ADD_DRIVE_FILE_VERSION = 'ADD_DRIVE_FILE_VERSION';
export const ADD_DRIVE_FOLDER = 'ADD_DRIVE_FOLDER';
export const UPDATE_DRIVE = 'UPDATE_DRIVE';
export const UPDATE_DRIVE_FILE = 'UPDATE_DRIVE_FILE';
export const WOPI_UPDATE_FILE_CONTENT = 'WOPI_UPDATE_FILE_CONTENT';
export const UPDATE_DRIVE_FILE_VERSION = 'UPDATE_DRIVE_FILE_VERSION';
export const UPDATE_DRIVE_FOLDER = 'UPDATE_DRIVE_FOLDER';
export const DELETE_DRIVE = 'DELETE_DRIVE';
export const DELETE_DRIVE_FILE = 'DELETE_DRIVE_FILE';
export const DELETE_DRIVE_FOLDER = 'DELETE_DRIVE_FOLDER';
export const SET_SELECTED_DRIVE = 'SET_SELECTED_DRIVE';

export const getDrivesList = ({
  archived,
  limit,
  offset,
  sortKey,
  sortDirection,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    archived,
    limit,
    offset,
    sortKey,
    sortDirection,
  };

  return async (dispatch) => {
    dispatch({ type: GET_DRIVES });
    try {
      const res = await axios.get(`${URL}/drive/drives`, { params });
      dispatch({
        type: GET_DRIVES_SUCCESS,
        payload: res.data,
      });

    } catch ({ response }) {
      dispatch({
        type: GET_DRIVES_ERROR,
        payload: response?.data,
      });
      throw response?.data;
    }
  };
};

export const getDrivesBreadcrumb = (folderId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_DRIVES_BREADCRUMB });
    try {
      const res = await axios.get(`${URL}/drive/folders/${folderId}`);
      dispatch({
        type: GET_DRIVES_BREADCRUMB_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_DRIVES_BREADCRUMB_ERROR,
        payload: response?.data,
      });
      throw response?.data;
    }
  };
};

export const setEmptyBreadcrumb = () => {
  return (dispatch) =>
    dispatch({
      type: GET_DRIVES_BREADCRUMB_SUCCESS,
      payload: null,
    });
};

export const getDriveTree = ({
  archived,
  drive,
  limit,
  offset,
  parent,
  text,
  nested,
  sortKey,
  sortDirection,
  status,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const params = {
    archived,
    drive,
    limit,
    offset,
    parent,
    text,
    nested,
    sortKey,
    sortDirection,
    status,
  };

  return async (dispatch) => {
    dispatch({ type: GET_DRIVES_TREE });
    try {
      const res = await axios.get(`${URL}/drive/tree`, { params });
      dispatch({
        type: GET_DRIVES_TREE_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_DRIVES_TREE_ERROR,
        payload: response?.data,
      });
      throw response?.data;
    }
  };
};

export const deleteDriveTree = (driveId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      return await axios.delete(`${URL}/drive/tree?drive=${driveId}`, {data});
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const setSelectedDrive = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_DRIVE,
      payload: data,
    });
  };
};

export const wopiGetFileInfo = (versionId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: WOPI_GET_DRIVE_FILE_INFO });
    try {
      const res = await axios.get(`${URL}/drive/wopi/files/${versionId}`);
      dispatch({
        type: WOPI_GET_DRIVE_FILE_INFO_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: WOPI_GET_DRIVE_FILE_INFO_ERROR,
        payload: response?.data,
      });
      throw response?.data;
    }
  };
};

export const wopiGetFileContent = (versionId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: WOPI_GET_DRIVE_FILE_CONTENT });
    try {
      const res = await axios.get(`${URL}/drive/wopi/files/${versionId}/contents`);
      dispatch({
        type: WOPI_GET_DRIVE_FILE_CONTENT_SUCCESS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: WOPI_GET_DRIVE_FILE_CONTENT_ERROR,
        payload: response?.data,
      });
      throw response?.data;
    }
  };
};

export const downloadDriveFile = (fileId, versionId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_DRIVE_FILE_DOWNLOAD_URL });
    try {
      const res = await axios.get(`${URL}/drive/files/${fileId}/versions/${versionId}/url`);
      window.open(res.data);
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const getSearchResults = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_DRIVE_SEARCH_RESULTS });
    try {
      const res = await axios.get(`${URL}/drive/tree`, { params: data });
      dispatch({
        type: GET_DRIVE_SEARCH_RESULTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_DRIVE_SEARCH_RESULTS_ERROR,
        payload: response?.data?.message || response?.data,
      });
      throw response?.data?.message || response?.data;
    }
  };
};

export const getSearchContent = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_DRIVE_SEARCH_CONTENT_RESULTS });
    try {
      const res = await axios.get(`${URL}/drive/files/search`, {
        params: data,
      });
      dispatch({
        type: GET_DRIVE_SEARCH_CONTENT_RESULTS_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_DRIVE_SEARCH_CONTENT_RESULTS_ERROR,
        payload: response?.data?.message || response?.data,
      });
      throw response?.data?.message || response?.data;
    }
  };
};

export const getDriveTrash = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: GET_DRIVE_TRASH });
    try {
      const res = await axios.get(`${URL}/drive/tree`, { params: data });
      dispatch({
        type: GET_DRIVE_TRASH_SUCCESS,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GET_DRIVE_TRASH_ERROR,
        payload: response?.data?.message || response?.data,
      });
      throw response?.data?.message || response?.data;
    }
  };
};

export const emptyTrash = (drive) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({type: EMPTY_DRIVE_TRASH});
    try {
      const res = await axios.delete(`${URL}/drive/tree/trash/?drive=${drive}`);
      dispatch({type: EMPTY_DRIVE_TRASH_SUCCESS});
      return res.data;
    } catch ({ response }) {
      dispatch({
        type: EMPTY_DRIVE_TRASH_ERROR,
        payload: response?.data?.message || response?.data,
      });
      throw response?.data?.message || response?.data;
    }
  };
};

export const clearTrashOnTabChange = () => {
  return (dispatch) => {
    dispatch({type: EMPTY_DRIVE_TRASH_SUCCESS});
  }
}

export const updateDriveTree = (driveId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.patch(`${URL}/drive/tree?drive=${driveId}`, data);
      return res.data;
    } catch ({ response }) {
      throw response?.data?.message || response?.data;
    }
  };
};

export const downloadDriveFolderZip = (folderName, folderId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_DRIVE_FOLDER_ZIP });
    try {
      const res = await axios.get(
       `${URL}/drive/folders/${folderId}/download`,
       {
         responseType: 'blob'
       }
      );
      const fileName = folderName + ".zip";
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute("download", fileName);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const addDrive = (navigate, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/drive/drives`, data);
      dispatch({
        type: ADD_DRIVE,
        payload: res.data,
      });
      navigate(`/drive/${res.data._id}`);
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const addFile = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/drive/files`, data);
      if (res.data) {
        return res.data;
      }
    } catch ({ response }) {
      throw response && response.data;
    }
  };
};

export const addBulkFolders = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/drive/folders/bulk`, data);
      if (res.data) {
        return res.data;
      }
    } catch ({ response }) {
      notification.error({
        message: 'Error',
        description: response?.data?.message,
      });
    }
  };
};

export const addBulkFiles = (data) => {
  setAuthToken(localStorage.getItem('token'));

  return async () => {
    try {
      const res = await axios.post(`${URL}/drive/files/bulk`, data);
      if (res.data) {
        return res.data;
      }
    } catch ({ response }) {
      notification.error({
        message: 'Error',
        description: response?.data?.message,
      });
    }
  };
};

export const uploadFileToAmazonS3 = (versions, attachment) => {
  setAuthToken(null);

  return async () => {
    try {
      if (versions[0]?.url) {
        return await axios.put(versions[0]?.url, attachment, {
          headers: {
            'Content-Type': attachment.type,
          }
        });
      }
    } catch ({ response }) {
      notification.error({
        message: 'Error',
        description: response?.data?.message,
      });
    }
  }
}

export const wopiHandleHeaders = (versionId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/drive/wopi/files/${versionId}`);
      dispatch({
        type: WOPI_HANDLE_HEADERS,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const addFileVersion = (fileId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/drive/files/${fileId}/versions`);
      dispatch({
        type: ADD_DRIVE_FILE_VERSION,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const addDriveFolder = ({
  archived,
  name,
  drive,
  parent,
}) => {
  setAuthToken(localStorage.getItem('token'));

  const data = {
    archived,
    name,
    drive,
    parent,
  };

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/drive/folders`, data);
      dispatch({
        type: ADD_DRIVE_FOLDER,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const updateDrive = (driveId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/drive/drives/${driveId}`, data);
      dispatch({
        type: UPDATE_DRIVE,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const updateFile = (fileId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/drive/files/${fileId}`, data);
      dispatch({
        type: UPDATE_DRIVE_FILE,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const wopiUpdateFileContent = (versionId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.post(`${URL}/drive/wopi/files/${versionId}/contents`);
      dispatch({
        type: WOPI_UPDATE_FILE_CONTENT,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const updateFileVersion = ({
  fileId,
  versionId,
  status,
  wopi
}) => {
  setAuthToken(localStorage.getItem('token'));

  const data = {
    status,
    wopi,
  };

  return async (dispatch) => {
    try {
      const res = await axios.patch(
        `${URL}/drive/files/${fileId}/versions/${versionId}`,
        data,
      );
      dispatch({
        type: UPDATE_DRIVE_FILE_VERSION,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      notification.error({
        message: 'Error',
        description: response?.data?.message,
      });
    }
  };
};

export const updateDriveFolder = (folderId, data) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      const res = await axios.patch(`${URL}/drive/folders/${folderId}`, data);
      dispatch({
        type: UPDATE_DRIVE_FOLDER,
        payload: res.data,
      });
      return res.data;
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const deleteFile = (fileId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/drive/files/${fileId}`);
      dispatch({
        type: DELETE_DRIVE_FILE,
        payload: fileId,
      });
    } catch ({ response }) {
      throw response?.data;
    }
  };
};

export const deleteDriveFolder = (folderId) => {
  setAuthToken(localStorage.getItem('token'));

  return async (dispatch) => {
    try {
      await axios.delete(`${URL}/drive/folders/${folderId}`);
      dispatch({
        type: DELETE_DRIVE_FOLDER,
        payload: folderId,
      });
    } catch ({ response }) {
      throw response?.data;
    }
  };
};
