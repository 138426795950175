import {
  AUTHENTICATE_CHAT,
  AUTHENTICATE_CHAT_SUCCESS,
  AUTHENTICATE_CHAT_ERROR,
  RECEIVE_MESSAGE,
  SEND_MESSAGE,
  GET_SUBSCRIPTION_MESSAGES,
  GET_SUBSCRIPTION_MESSAGES_SUCCESS,
  DISCONNECT_CHAT,
  RECEIVE_TYPING,
  EDIT_MESSAGE,
  SEND_MESSAGE_ERROR,
  GET_UNREAD_MESSAGES,
  SOCKET_CONNECT,
  SOCKET_CONNECT_ERROR,
  SOCKET_CONNECT_SUCCESS,
  SOCKET_DISCONNECT,
  SOCKET_RECONNECT,
  SOCKET_RECONNECT_SUCCESS,
  SOCKET_RECONNECT_ERROR,
  TOGGLE_CHAT,
  EDIT_CHAT_WIDTH,
} from '../actions/chatActions';

const initialState = {
  open: false,
  connected: false,
  connecting: false,
  reconnecting: false,
  error: '',
  width: 420,
  sending: false,
  subscriptions: {},
  unreadMentions: {
    total: 0,
    objections: {},
    filings: {},
    filingDrafts: {},
    filingForms: {},
    filingRates: {},
    projects: {},
    isFetched: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SOCKET_CONNECT: {
      return {
        ...state,
        reconnecting: false,
        connected: false,
        connecting: true,
      };
    }
    case SOCKET_CONNECT_ERROR: {
      return state;
    }
    case SOCKET_CONNECT_SUCCESS: {
      return {
        ...state,
        connected: true,
        connecting: false,
        reconnecting: false,
      };
    }
    case SOCKET_DISCONNECT: {
      return initialState;
    }
    case SOCKET_RECONNECT: {
      return {
        ...state,
        connected: false,
        connecting: false,
        reconnecting: true,
      };
    }
    case SOCKET_RECONNECT_SUCCESS: {
      return {
        ...state,
        connected: true,
        connecting: false,
        reconnecting: false,
      };
    }
    case SOCKET_RECONNECT_ERROR: {
      return state;
    }
    case AUTHENTICATE_CHAT: {
      return {
        ...state,
        connected: false,
        connecting: true,
        reconnecting: false,
        error: '',
        // subscriptions: {},
        sending: false,
        unreadMentions: {
          total: 0,
          objections: {},
          filings: {},
          filingDrafts: {},
          projects: {},
          filingForms: {},
          filingRates: {},
          isFetched: false,
        },
      };
    }
    case AUTHENTICATE_CHAT_SUCCESS: {
      return {
        ...state,
        connected: true,
        connecting: false,
        reconnecting: false,
        error: '',
        // subscriptions: {},
        sending: false,
        unreadMentions: {
          total: 0,
          objections: {},
          filings: {},
          filingDrafts: {},
          projects: {},
          filingForms: {},
          filingRates: {},
          isFetched: false,
        },
      };
    }
    case AUTHENTICATE_CHAT_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        connected: false,
        connecting: false,
        reconnecting: false,
        error,
        // subscriptions: {},
        sending: false,
        unreadMentions: {
          total: 0,
          objections: {},
          filings: {},
          filingDrafts: {},
          projects: {},
          filingForms: {},
          filingRates: {},
          isFetched: false,
        },
      };
    }
    case DISCONNECT_CHAT: {
      return {
        ...state,
        connected: false,
        connecting: false,
        reconnecting: false,
        error: '',
        width: 420,
        subscriptions: {},
        sending: false,
        unreadMentions: {
          total: 0,
          objections: {},
          filings: {},
          filingDrafts: {},
          projects: {},
          filingForms: {},
          filingRates: {},
          isFetched: false,
        },
      };
    }
    case GET_SUBSCRIPTION_MESSAGES: {
      const { objectId } = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [objectId]: {
            isLoading: true,
            messages: [],
          },
        },
      };
    }
    case GET_SUBSCRIPTION_MESSAGES_SUCCESS: {
      const { objectId, messages } = action.payload;
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [objectId]: {
            isLoading: false,
            messages,
          },
        },
      };
    }
    case SEND_MESSAGE: {
      return {
        ...state,
        sending: true,
      };
    }
    case SEND_MESSAGE_ERROR: {
      return {
        ...state,
        sending: false,
      };
    }
    case EDIT_MESSAGE: {
      const { _id, objectId } = action.payload;
      const subscription = state.subscriptions[objectId];

      return {
        ...state,
        sending: false,
        subscriptions: {
          ...state.subscriptions,
          [objectId]: {
            ...state.subscriptions[objectId],
            messages: subscription && subscription.messages && subscription.messages.length
              ? subscription.messages.map(message => (message._id === _id
                ? action.payload
                : message)) : [],
          },
        },
      };
    }
    case RECEIVE_TYPING: {
      const {
        objectId,
        data,
        userId,
      } = action.payload;
      const subscription = state.subscriptions[objectId];
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [objectId]: {
            ...state.subscriptions[objectId],
            typing: {
              ...(
                subscription
                  ? { ...subscription.typing }
                  : {}
              ),
              [userId]: {
                isTyping: data.typing,
              },
            },
          },
        },
      };
    }
    case RECEIVE_MESSAGE: {
      const { objectId } = action.payload;
      const subscription = state.subscriptions[objectId];
      return {
        ...state,
        sending: false,
        subscriptions: {
          ...state.subscriptions,
          [objectId]: {
            isLoading: false,
            messages: [
              ...(
                subscription && subscription.messages && subscription.messages.length
                  ? [...subscription.messages]
                  : []
              ),
              action.payload,
            ],
          },
        },
      };
    }
    case GET_UNREAD_MESSAGES: {
      const unreadMentions = action.payload;

      return {
        ...state,
        unreadMentions: {
          total: unreadMentions.total,
          filingForms: unreadMentions?.filingForms?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          filingRates: unreadMentions?.filingRates?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          objections: unreadMentions?.objections?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          filings: unreadMentions?.filings?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          filingDrafts: unreadMentions?.filingDrafts?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          projects: unreadMentions?.projects?.reduce((acc, num) => {
            acc[num._id] = num.count;
            return acc;
          }, {}),
          isFetched: true,
        },
      };
    }
    case TOGGLE_CHAT: {
      return {
        ...state,
        width: 420,
        open: action.payload,
      };
    }
    case EDIT_CHAT_WIDTH: {
      return {
        ...state,
        width: state.width + action.payload,
      };
    }
    default:
      return state;
  }
}
