import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { message } from 'antd';
import { searchProjects } from '../../actions/projectsActions';
import Select from '../../general/Select';

const LIMIT = 50;

const ProjectsTypeAhead = ({
  productIds = [],
  usePortal = true,
  label,
  required,
  multiple,
  disabled,
  searchable,
  error,
  value,
  onChange,
}) => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const shouldFetchNextPage = useRef(true);

  const [data, setData] = useState({
    items: [],
    count: 0,
  });

  const pagination = useRef({
    limit: LIMIT,
    offset: 0,
  });

  useEffect(() => {
    if (!productIds?.length) {
      return;
    }
    shouldFetchNextPage.current = true;
    setQuery('');
    setData({
      items: [],
      count: 0,
    });
    pagination.current = {
      limit: LIMIT,
      offset: 0,
    };
  }, [productIds]);

  const fetchProjects = async (searchQuery) => {
    if (!shouldFetchNextPage.current) {
      return;
    }

    try {
      setLoading(true);
      const projects = await searchProjects({
        limit: pagination.current.limit,
        offset: pagination.current.offset,
        sortDirection: 1,
        sortKey: 'name',
        fields: ['_id', 'name'],
        filters: [
          {
            field: 'archived',
            comparisonOperator: 'eq',
            value: false,
          },
          ...searchQuery !== null && query ? [{
            field: 'names',
            comparisonOperator: 'cn',
            value: [query],
          }] : [],
          ...productIds?.length ? [{
            field: 'productIds',
            comparisonOperator: 'in',
            value: productIds,
          }] : [],
        ],
      });
      setLoading(false);
      setData(prev => {
        const items = [
          ...prev.items,
          ...projects.items.map(project => ({
            label: project.name,
            value: project._id,
          }))
        ];

        if (items.length === projects.count) {
          shouldFetchNextPage.current = false;
        }

        return {
          items,
          count: projects.count,
        };
      });
    } catch (err) {
      setLoading(false);
      message.error({
        content: (err && err.message) || 'Error',
      });
    }
  };

  const onScroll = () => {
    pagination.current = {
      limit: pagination.current.limit,
      offset: pagination.current.offset + pagination.current.limit,
    };

    fetchProjects();
  };

  const onSearch = () => {
    shouldFetchNextPage.current = true;
    setData({
      items: [],
      count: 0,
    });
    pagination.current = {
      limit: LIMIT,
      offset: 0,
    };
    fetchProjects();
  };

  const debounceFetchProjects = useDebouncedCallback(onSearch, 1000);

  return (
    <Select
      usePortal={usePortal}
      required={required}
      disabled={disabled}
      multiple={multiple}
      searchable={searchable}
      error={error}
      asyncSearch
      loading={loading}
      label={label}
      options={data.items}
      value={value}
      onChange={onChange}
      onLoadNextPage={onScroll}
      onMenuOpen={() => {
        shouldFetchNextPage.current = true;
        setQuery('');
        setData({
          items: [],
          count: 0,
        });
        pagination.current = {
          limit: LIMIT,
          offset: 0,
        };

        fetchProjects();
      }}
      onMenuClose={() => {
        shouldFetchNextPage.current = true;
        setQuery('');
        setData({
          items: [],
          count: 0,
        });
        pagination.current = {
          limit: LIMIT,
          offset: 0,
        };
      }}
      onAsyncSearch={(e, b) => {
        if (b.action !== 'input-change') {
          return;
        }

        setQuery(e);
        debounceFetchProjects();
      }}
    />
  );
};

export default ProjectsTypeAhead;
