import { useSelector } from 'react-redux';
import { PERMISSION } from './RoleService';
import {
  hasAnalyticsFeature,
  hasCustomRolesFeature,
  hasFilingDraftsFeature,
  hasRampDriveFeature,
  hasWorkflowFeature,
} from '../selectors/selectors';

const SYSTEM_ROLE = {
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
};

export const usePermissions = () => {
  const {
    userRole,
    customRole,
    hasWorkflow,
    hasRampDrive,
    hasFilingDrafts,
    hasAnalytics,
    hasCustomRoles,
  } = useSelector(state => ({
    userRole: state.user?.data?.role,
    customRole: state?.user?.data?.roles[0],
    hasCustomRoles: hasCustomRolesFeature(state),
    hasWorkflow: hasWorkflowFeature(state),
    hasRampDrive: hasRampDriveFeature(state),
    hasFilingDrafts: hasFilingDraftsFeature(state),
    hasAnalytics: hasAnalyticsFeature(state),
  }));

  const ROUTES = [
    {
      key: 'DASHBOARD',
      url: '/dashboard',
    },
    ...hasFilingDrafts ? [{
      key: 'FILING_DRAFTS',
      url: '/filing-drafts',
    }] : [],
    {
      key: 'FILINGS',
      url: '/products',
    },
    ...hasWorkflow ? [{
      key: 'OBJECTIONS',
      url: '/objections',
    }] : [],
    {
      key: 'LIBRARY',
      url: '/forms-library?archived=false',
    },
    ...hasRampDrive ? [{
      key: 'DRIVE',
      url: '/drive',
    }] : [],
    {
      key: 'REPORTING',
      url: '/reporting',
    },
    ...hasAnalytics ? [{
      key: 'ANALYTICS',
      url: '/analytics',
    }] : [],
    {
      key: 'ORGANIZER',
      url: '/organizer',
    },
    {
      key: 'ADMIN',
      url: '/admin',
    },
  ]

  const isAdmin = userRole === SYSTEM_ROLE.ADMIN;
  const isAccountAdmin = userRole === SYSTEM_ROLE.ACCOUNT_ADMIN;
  const isMember = userRole === SYSTEM_ROLE.MEMBER;

  const getFeaturePermission = (feature) => customRole
    && customRole.features
    && customRole.features[feature];

  const hasPartialPermission = (feature) => {
    if (customRole && hasCustomRoles) {
      if (customRole.archived) {
        return false;
      }
      const permission = getFeaturePermission(feature);
      return permission === PERMISSION.READ || permission === PERMISSION.WRITE;
    }

    return true;
  };

  const hasWritePermission = (feature) => {
    if (customRole && hasCustomRoles) {
      if (customRole.archived) {
        return false;
      }
      const permission = getFeaturePermission(feature);
      return permission === PERMISSION.WRITE;
    }

    return true;
  };

  const getFirstAccessibleRoute = () => {
    if (customRole && hasCustomRoles) {
      if (customRole.archived) {
        return '/no-access?role-archived=true';
      }

      const firstAccessibleRoute = ROUTES.find(route => {
        const customRoleFeature = customRole.features[route.key];
        return customRoleFeature === PERMISSION.READ || customRoleFeature === PERMISSION.WRITE;
      });

      return firstAccessibleRoute
        ? firstAccessibleRoute.url
        : '/no-access';
    }

    return '/dashboard';
  }

  return {
    isAdmin,
    isAccountAdmin,
    isMember,
    customRole,
    firstAccessibleUrl: getFirstAccessibleRoute(),
    getFeaturePermission,
    hasPartialPermission,
    hasWritePermission,
  };
};
